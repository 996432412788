<template>
  <main class="accountAuth">
    <b-breadcrumb :items="breadcrumb" />
    <div class="container" :class="{ 'mx-auto': !sidebarShow }">
      <SectionUserInfo v-if="forClient" />
      <AccountAuthGoogle
        v-if="accountType === 'google'"
        :tableShow="tableShow"
        :messageEventStatus="messageEventStatus"
        :accountStatus="accountAuth.google.status"
        :accountMsg="accountAuth.google.msg"
        @getAuthorisation="getAuthorisation"
      />
      <AccountAuthFacebook
        v-if="accountType === 'facebook'"
        :tableShow="tableShow"
        :messageEventStatus="messageEventStatus"
        :accountStatus="accountAuth.facebook.status"
        :accountMsg="accountAuth.facebook.msg"
        @getAuthorisation="getAuthorisation"
      />
    </div>
  </main>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import SectionUserInfo from '../components/SectionUserInfo.vue'
import AccountAuthGoogle from './AccountAuthGoogle.vue'
import AccountAuthFacebook from './AccountAuthFacebook.vue'

export default {
  name: 'AccountAuth',
  components: { SectionUserInfo, AccountAuthGoogle, AccountAuthFacebook },
  data() {
    return {
      accountType: null,
      domainPath: process.env.VUE_APP_DOMAIN,
      tableShow: false,
      accountAuth: {
        type: '',
        facebook: { status: '', msg: '' },
        google: { status: '', msg: '' }
      },
      messageEventStatus: false
    }
  },
  async mounted() {
    const accountType = this.$route.params.accountType
    this.accountType = accountType

    let status = await this['accountAuth/pageHomeCreated']()
    if (status === 'success') {
      this.tableShow = true
    } else {
      console.log('account-auth created: ' + status)
      this.$router.push('/pages/page403')
    }
  },
  computed: {
    ...mapState({
      sidebarShow: state => state.sidebarShow,
      user: state => state.user.self
    }),
    forClient() {
      return this.user.role === 'CLIENT'
    },
    breadcrumb() {
      return [
        {
          text: `${this.transformType(this.accountType)} Account Authorisation`,
          active: true
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'accountAuth/pageHomeCreated',
      'accountAuth/pageGetAccount',
      'google/getOauthStart',
      'facebook/getOauthStart'
    ]),
    transformType(s) {
      if (!s) return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    },
    getAuthorisation(type) {
      this.accountAuth.type = type
      this.accountAuth[type].status = ''
      this.accountAuth[type].msg = ''
      this.messageEventStatus = true
      this.openAuth(type)
    },
    async openAuth(type) {
      this.openWindow = window.open(
        '',
        '_blank',
        'width=500,height=700,top=500,left=500'
      )
      if (process.env.NODE_ENV === 'development') {
        this.openWindow.location = `/pages/authResponseTest?type=${type}`
      } else if (process.env.NODE_ENV === 'production') {
        let response = await this[`${type}/getOauthStart`]()
        if (response.status === 201) {
          const url = response.data.url
          this.openWindow.location = url
        }
      }

      this.messageAddEvent()
    },
    messageAddEvent() {
      window.addEventListener('message', this.authorisationMsg, false)
      this.openWindowTimer = setInterval(this.listenerOpenWindowClose, 100)
    },
    messageRemoveEvent() {
      window.removeEventListener('message', this.authorisationMsg, false)
      this.messageEventStatus = false
    },
    authorisationMsg(e) {
      if (e.origin !== this.domainPath) {
        this.messageRemoveEvent()
        return false
      } else {
        let { status, type } = e.data
        if (status === 'success' && type) {
          this.accountAuth[type].status = 'success'
          this.accountAuth[type].msg = `${type} authorisation success`
          this.getAccounts()
          this.messageRemoveEvent()
        } else if (type) {
          this.accountAuth[type].status = 'error'
          this.accountAuth[type].msg = `
          ${type} authorisation failed, please try it again!`
          this.messageRemoveEvent()
        }
      }
    },
    listenerOpenWindowClose() {
      if (this.openWindow.closed === true) {
        this.messageRemoveEvent()
        clearInterval(this.openWindowTimer)
        return
      }
    },
    async getAccounts() {
      await this['accountAuth/pageGetAccount']({
        type: this.accountAuth.type
      })
    }
  }
}
</script>
