<template>
  <b-modal
    centered
    size="md"
    no-close-on-backdrop
    hide-header-close
    v-model="isModalOpen"
  >
    <template #modal-title>Revoke Confirmation</template>
    <template #default>
      <div class="modal-custom-content">
        <p>
          Are you sure you want to revoke the authorisation of
          {{ accountInfo }} ?
        </p>
      </div>
    </template>
    <template #modal-footer>
      <div class="modal-btn text-right">
        <button class="btn btn-secondary" @click.prevent="$emit('no')">
          No
        </button>
        <button class="btn btn-primary" @click.prevent="$emit('yes')">
          Yes, revoke it
        </button>
      </div>
    </template>
  </b-modal>
</template>
<script>
export default {
  name: 'ModalAccountRevoke',
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    revokeAccountData: {
      type: Object,
      required: true
    }
  },
  computed: {
    isModalOpen: {
      get() {
        return this.isOpen
      },
      set() {
        this.$emit('update:isOpen', false)
      }
    },
    accountInfo() {
      if (!this.revokeAccountData.name) {
        return ''
      } else {
        let { name, accountType } = this.revokeAccountData
        return `${this.transformType(accountType)} account ${name}`
      }
    }
  },
  methods: {
    transformType(s) {
      if (!s) return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  }
}
</script>
