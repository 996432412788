<template>
  <div>
    <div class="section-table accountAuth-table">
      <div class="section-table-content">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" class="td accountAuth-td-status">
                Status
              </th>
              <th scope="col" class="td  accountAuth-td-name">
                Name
              </th>
              <th scope="col" class="td  accountAuth-td-time">
                Last Update
              </th>
              <th scope="col" class="td accountAuth-td-btn"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user of renderAccounts" :key="user.id">
              <td
                scope="row"
                class="td accountAuth-td-status"
                :class="{
                  'text-primary': user.status === 'ACTIVE',
                  'text-danger':
                    user.status === 'INACCESSIBLE' || user.status === 'INACTIVE'
                }"
              >
                {{ user.status }}
              </td>
              <td class="td accountAuth-td-name">
                {{ user.name }}
              </td>
              <td class="td accountAuth-td-time">
                {{ dateFormat(user.updatedAt) }}
              </td>
              <td class="td accountAuth-td-btn">
                <button
                  class="btn"
                  :class="
                    `btn-${user.status === 'ACTIVE' ? 'primary' : 'danger'}`
                  "
                  @click="reAuthzation()"
                  :disabled="messageEventStatus"
                >
                  Reauthorize
                </button>
                <button
                  class="btn btn-secondary"
                  @click="revokeModelOpen(user)"
                  :disabled="user.status !== 'ACTIVE'"
                >
                  revoke
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Pagination
      :current="currentPage"
      :total="accountsLength"
      :perPage="perPage"
      @currentPageUpdate="currentPageUpdateHandler"
    />
    <ModalAccountRevoke
      :isOpen="revokeModalShow"
      :revokeAccountData="revokeAccountData"
      @yes="revokeAccount"
      @no="revockModalCancel"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Pagination from './Pagination.vue'
import ModalAccountRevoke from './ModalAccountRevoke.vue'

export default {
  name: 'AccountAuthTable',
  components: { Pagination, ModalAccountRevoke },
  props: {
    accountType: {
      type: String,
      required: true
    },
    messageEventStatus: Boolean
  },
  data() {
    return {
      domainPath: process.env.VUE_APP_DOMAIN,
      revokeModalShow: false,
      revokeAccountData: {},

      //Pagination
      perPage: 5,
      currentPage: 1
    }
  },
  computed: {
    accounts() {
      return this.$store.state[this.accountType].accounts
    },
    //Pagination
    renderAccounts() {
      let start = (this.currentPage - 1) * this.perPage
      let end = start + this.perPage
      return this.accounts.slice(start, end)
    },
    accountsLength() {
      return this.accounts ? this.accounts.length : 0
    }
  },
  methods: {
    ...mapActions(['accountAuth/pageRevokeAccount']),
    //Pagination
    currentPageUpdateHandler(currentPage) {
      this.currentPage = currentPage
    },
    dateFormat(date) {
      if (date == null) return null
      return new Date(date).toLocaleString()
    },
    reAuthzation() {
      this.$emit('getAuthorisation')
    },
    revokeModelOpen(user) {
      let accountType = this.accountType
      this.revokeModalShow = true
      this.revokeAccountData = {
        ...user,
        accountType
      }
    },
    revockModalCancel() {
      this.revokeModalShow = false
      this.revokeAccountData = {}
    },
    async revokeAccount() {
      this.revokeModalShow = false
      const id = this.revokeAccountData.id
      let status = await this['accountAuth/pageRevokeAccount']({
        type: this.accountType,
        id
      })
      if (status === 'success') {
        this.revokeAccountData = {}
      } else {
        this.revokeAccountData = {}
        console.log('revoke Account:' + status)
        // this.$router.push('/pages/page403')
      }
    }
  }
}
</script>
