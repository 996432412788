<template>
  <section class="section" v-if="tableShow">
    <div class="section-title accountAuth-title">Facebook Account</div>
    <div class="section-table-status">
      <button
        class="btn btn-primary btn-auth"
        @click.prevent="getAccountAuth"
        :disabled="messageEventStatus"
      >
        New Authorisation
      </button>
    </div>
    <AccountAuthTable
      accountType="facebook"
      @getAuthorisation="getAccountAuth"
      :messageEventStatus.sync="messageEventStatus"
    />
    <div
      class="invalid-feedback"
      :class="accountStatus === 'success' ? 'text-success' : 'text-danger'"
      v-show="accountMsg"
    >
      {{ accountMsg }}
    </div>
  </section>
</template>

<script>
import AccountAuthTable from '../components/AccountAuthTable.vue'

export default {
  name: 'AccountAuthFacebook',
  components: { AccountAuthTable },
  props: {
    tableShow: {
      type: Boolean,
      required: true
    },
    messageEventStatus: {
      type: Boolean,
      required: true
    },
    accountStatus: {
      type: String,
      required: true
    },
    accountMsg: {
      type: String,
      required: true
    },
    getAuthorisation: {
      type: Function
    }
  },
  methods: {
    getAccountAuth() {
      this.$emit('getAuthorisation', 'facebook')
    }
  }
}
</script>
